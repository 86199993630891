// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 1em 1em 2em 1em;
}

.survey-title {
  padding: 2em 1em 1em 1em;
  background-color: black;
  color: white;
  text-align: center;
}

.survey-description {
  text-align: justify;
}

.skip-button {
  float: right;
}

.question-title {
  font-size: large;
  text-align: justify;
  font-weight: 600;
}

.numeric-range-container {
  max-width: 40em;
}

input[type="radio"], input[type="check"], input[type="text"], input[type="date"] {
  border-color: darkgray;
}

.loading-div {
  text-align: center;
  padding: 2em;
}

.finish-div{
  text-align: center;
  padding: 0.5em
}

.yesno-btn {
  width: 100%;
  font-size: large;
}

.yesno-container {
  margin-left: 0%;
  max-width: 25em;
}

.survey-buttons {
  max-width: 40em;
}

.finish-button-container {
  text-align: center;
}

.finish-button {
  font-size: x-large;
  width: 75%;
}

.help-button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nmain {\n  padding: 1em 1em 2em 1em;\n}\n\n.survey-title {\n  padding: 2em 1em 1em 1em;\n  background-color: black;\n  color: white;\n  text-align: center;\n}\n\n.survey-description {\n  text-align: justify;\n}\n\n.skip-button {\n  float: right;\n}\n\n.question-title {\n  font-size: large;\n  text-align: justify;\n  font-weight: 600;\n}\n\n.numeric-range-container {\n  max-width: 40em;\n}\n\ninput[type=\"radio\"], input[type=\"check\"], input[type=\"text\"], input[type=\"date\"] {\n  border-color: darkgray;\n}\n\n.loading-div {\n  text-align: center;\n  padding: 2em;\n}\n\n.finish-div{\n  text-align: center;\n  padding: 0.5em\n}\n\n.yesno-btn {\n  width: 100%;\n  font-size: large;\n}\n\n.yesno-container {\n  margin-left: 0%;\n  max-width: 25em;\n}\n\n.survey-buttons {\n  max-width: 40em;\n}\n\n.finish-button-container {\n  text-align: center;\n}\n\n.finish-button {\n  font-size: x-large;\n  width: 75%;\n}\n\n.help-button {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
